import { createContext, useContext } from 'react';

export type UserContextType = {
  isAuthenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  email: string;
  roleLevel: number;
};

export const UserContext = createContext<UserContextType | null>(null);
export const useUserContext = () => useContext(UserContext);
