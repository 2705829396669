import * as React from 'react';
import Spinner from '../spinner/Spinner';

interface IButtonProps {
  className?: string;
  color?: 'white' | 'red' | 'blue' | 'green' | 'transparent' | 'yellow' | 'gray';
  disabled?: boolean;
  form?: string;
  isLoading?: boolean;
  onClick?: (...args) => void;
  onKeyDown?: (...args) => void;
  type?: 'submit' | 'button';
  value?: React.ReactNode;
  visible?: boolean;
  name?: string;
  btnValue?: string | number;
  icon?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}
function Button(props: IButtonProps) {
  const {
    disabled, value, isLoading, onClick, onKeyDown, type, className, color, form, visible, name, btnValue, icon, size,
  } = props;
  const colorStyle = {
    white: 'bg-white hover:bg-gray-200 text-black',
    red: 'bg-red-600 hover:bg-red-800 text-white',
    blue: 'bg-blue-600 hover:bg-blue-800 text-white',
    green: 'bg-green-600 hover:bg-green-800 text-white',
    transparent: 'text-blue-600 hover:text-blue-800',
    yellow: 'bg-yellow-400 text-black hover:bg-yellow-800',
    gray: 'bg-gray-400 text-black hover:bg-gray-800',
  };
  const disabledStyle = {
    white: 'bg-gray-300 hover:bg-gray-300 ',
    red: 'bg-gray-300 hover:bg-gray-300 ',
    blue: 'bg-gray-300 hover:bg-gray-300 ',
    green: 'bg-gray-300 hover:bg-gray-300 ',
    transparent: 'text-gray-400 hover:text-gray-400 ',
    yellow: 'bg-gray-300 hover:bg-gray-300',
    gray: 'bg-gray-300 hover:bg-gray-300',
  };
  const sizeStyle = {
    sm: 'h-8',
    md: 'h-10',
    lg: 'h-12',
  };
  const disabledButtonStyle = disabled
    ? `${disabledStyle[color]} drop-shadow-none hover:drop-shadow-none cursor-not-allowed`
    : `${colorStyle[color]} drop-shadow-md hover:drop-shadow-lg`;

  return (
    <>
      {null}
      {visible
    && (
      <button
        className={`${className} ${disabledButtonStyle} ${sizeStyle[size]}`}
        disabled={disabled}
        form={form}
        onClick={onClick}
        onKeyDown={onKeyDown}
        type={type === 'submit' ? 'submit' : 'button'}
        name={name}
        value={btnValue}
      >
        <div className="flex justify-between items-center gap-2">
          <span className={isLoading ? '' : 'w-full'}>{value}</span>
          {icon}
          {isLoading && (
            <Spinner />
          ) }
        </div>
      </button>
    )}
    </>
  );
}

Button.defaultProps = {
  className: 'px-5 h-10 rounded-md',
  color: 'blue',
  disabled: false,
  form: undefined,
  isLoading: false,
  onClick: () => null,
  onKeyDown: () => null,
  type: 'button',
  value: 'Save',
  visible: true,
  name: '',
  btnValue: '',
  icon: null,
  size: 'md',
};

export default Button;
