import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { classNames } from 'utility/classNames';

const colorStyle = {
  white: 'bg-white hover:bg-gray-200 text-black',
  red: 'bg-red-600 hover:bg-red-800 text-white',
  blue: 'bg-blue-600 hover:bg-blue-800 text-white',
  green: 'bg-green-600 hover:bg-green-800 text-white',
};

const sizeStyle = {
  xs: 'py-1 px-2 rounded-md text-xs',
  sm: 'py-1 px-4 rounded-md text-sm',
  md: 'py-2 px-5 rounded-md text-base',
  lg: 'py-2 px-6 rounded-md text-lg',
};

interface IButtonProps {
  className?: string;
  color?: 'white' | 'red' | 'blue' | 'green';
  disabled?: boolean;
  form?: string;
  isLoading?: boolean;
  onClick?: (...args) => void;
  onKeyDown?: (...args) => void;
  type?: 'submit' | 'button';
  visible?: boolean;
  name?: string;
  icon?: 'edit' | 'copy';
  size?: 'xs' |'sm' | 'md' | 'lg'
}

function ButtonIcon(props: IButtonProps) {
  const {
    disabled, isLoading, onClick, onKeyDown, type, className, color, form, visible, name, icon, size,
  } = props;

  const disabledButtonStyle = disabled
    ? 'bg-gray-300 hover:bg-gray-300 drop-shadow-none hover:drop-shadow-none cursor-not-allowed'
    : `${colorStyle[color]} drop-shadow-md hover:drop-shadow-lg`;

  return (
    <>
      {null}
      {visible
        && (
          <button
            className={classNames(` ${disabledButtonStyle} ${sizeStyle[size]}`, className)}
            disabled={disabled}
            form={form}
            onClick={onClick}
            onKeyDown={onKeyDown}
            type={type === 'submit' ? 'submit' : 'button'}
            name={name}
          >
            <div className="flex justify-between items-center gap-2">
              <span className={isLoading ? '' : 'w-full'}>
                {icon === 'edit' && <EditIcon fontSize="inherit" />}
                {icon === 'copy' && <ContentCopyIcon fontSize="inherit" />}
              </span>
            </div>
          </button>
        )}
    </>
  );
}

ButtonIcon.defaultProps = {
  className: '',
  color: 'blue',
  disabled: false,
  form: undefined,
  isLoading: false,
  onClick: () => null,
  onKeyDown: () => null,
  type: 'button',
  visible: true,
  name: '',
  icon: null,
  size: 'xs',
};

export default ButtonIcon;
