import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  UserIcon, MenuIcon, XIcon, ChevronDownIcon,
} from '@heroicons/react/outline';
import { useLocation, useNavigate } from 'react-router';
import { useUserContext } from 'context/user';
import { classNames } from 'utility/classNames';
import NavDropdown from './NavDropdown';
import packageJson from '../../../package.json';


export default function NavBar() {
  const userContext = useUserContext();

  const location = useLocation();
  const navigate = useNavigate();
  const page = location.pathname.split('/')[1];
  const pathName = location.pathname;
  const [navigation, setNavigation] = useState([]);
  // const { instance } = useMsal();

  const populateMenu = async () => {
    const menu = [];
    menu.push({ name: 'Dashboard', href: '/' });

    menu.push({ name: 'List Project CR', href: '/project' });

    if (userContext.roleLevel > 10000) {
      menu.push({
        isDropdown: true,
        name: 'Admin CR',
        items: [{ name: 'List CR Member', href: '/admin-cr' }, {name: 'List Assignment Member', href: '/assigment-cr'}],
      });
    }

    // menu.push({
    //   isDropdown: true,
    //   name: 'Dokumen',
    //   items: [{ name: 'Surat Peringatan', href: '/surat-peringatan' }],
    // });

    setNavigation(menu);
  };

  useEffect(() => {
    if (userContext.roleLevel && userContext.isAuthenticated) {
      populateMenu();
    } else {
      setNavigation([]);
    }
  }, [userContext.roleLevel, userContext.isAuthenticated]);

  const handleLogout = () => {
    window.localStorage.clear();
    userContext.setAuthenticated(false);
    return navigate('login', { replace: true });
  };

  // to be used later
  // const handleLogoutOAuth365 = () => {
  //   window.localStorage.clear();
  //   userContext.setAuthenticated(false);
  //   instance.logoutPopup({
  //     postLogoutRedirectUri: '/',
  //     mainWindowRedirectUri: '/',
  //   });
  // };

  const handleClickRoute = (e, url, close = undefined) => {
    e.preventDefault();
    if (close) close();
    navigate(url);
  };

  const handleClickProfile = () => navigate('/profile');

  useEffect(() => {
    if (page !== 'login' && !userContext.isAuthenticated) {
      // handleLogout();
    }
  }, [pathName]);

  return (
    <Disclosure as="nav" className="bg-primary">
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              {userContext.isAuthenticated && navigation.length > 0 && (
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    className="inline-flex items-center text-slate-300 justify-center p-2 rounded-md
                  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? <XIcon className="block h-6 w-6" aria-hidden="true" /> : <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              )}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <div className="relative h-8 w-32">
                    {/* <img src={LogoKomunal} alt="Komunal-Logo" className="absolute top-1/2 left-0 transform -translate-y-1/2 object-cover h-36 w-36" /> */}
                  </div>
                </div>
                {userContext.isAuthenticated && navigation.length > 0 && (
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => {
                        if (item.isDropdown) {
                          return (
                            <div key={item.name}>
                              <NavDropdown items={item.items} name={item.name} onClickRoute={handleClickRoute} />
                            </div>
                          );
                        }
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={(e) => handleClickRoute(e, item.href)}
                            className={classNames('px-2 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-500')}
                          >
                            {item.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {userContext.isAuthenticated && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div>
                  <span className="text-white text-xs hidden lg:block">{`Version ${packageJson.version}`}</span>
                  </div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button
                        className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-offset-gray-800 focus:ring-white"
                      >
                        <UserIcon className="block h-8 w-8 p-2" aria-hidden="true" />
                        <span className="sr-only">Open user menu</span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="origin-top-right z-30 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <p className={classNames('block px-4 py-2 text-xs text-right text-gray-500 lg:hidden border-b')}>{packageJson.version}</p>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={handleClickProfile}
                              onKeyDown={handleClickProfile}
                              role="button"
                              tabIndex={0}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700 border-b')}
                            >
                              Profile
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={handleLogout}
                              onKeyDown={handleLogout}
                              role="button"
                              tabIndex={0}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          {/* mobile */}
          <Disclosure.Panel className="sm:hidden">
            {navigation.length && (
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => {
                  const a = '';
                  return item.isDropdown ? (
                    <Disclosure key={item.name}>
                      {({ open: openMobile }) => (
                        <>
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="flex justify-between px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                          >
                            <span>{a}</span>
                            <span>{item.name}</span>
                            <ChevronDownIcon className={`${openMobile ? 'rotate-180 transform' : ''} h-5 w-5 text-white`} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 py-2">
                            <ul>
                              {item.items.map((i) => (
                                <li>
                                  <a
                                    key={i.name}
                                    href={i.href}
                                    onClick={(e) => handleClickRoute(e, i.href)}
                                    className={classNames('flex justify-between px-3 py-2.5 rounded-md text-base font-medium text-white hover:bg-gray-700')}
                                  >
                                    {i.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={(e) => handleClickRoute(e, item.href)}
                      className={classNames('flex justify-between px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700')}
                    >
                      {item.name}
                    </a>
                  );
                })}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
