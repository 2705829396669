import { Grid } from '@mui/material';
import { Table } from 'components/dx';
import PageHeader from 'components/PageHeader';
import { useUserContext } from 'context/user';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IColumns } from 'components/dx/table/Table';
import { useAppGlobalDialog } from 'utility/popup/dialog/AppGlobalDialogContext';
import { useNavigate } from 'react-router';
import { getReportList } from 'client/request/reports';
import { asyncWrapper } from 'utility/asyncWrapper';
import { SnackbarContext } from 'context/snackbar';

export default function ReportListScreen() {
  const navigate = useNavigate();
  const snackbar = useContext(SnackbarContext);
  const userContext = useUserContext();
  const [dataList, setDataList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    apiGetReportList();
  }, []);

  const apiGetReportList = async () => {


    setIsLoading(true);
    const [res, err] = await asyncWrapper(getReportList());
    if (err) snackbar.show(err, 'error');
    if (res) {
      const loanListApiResponse = res.data?.payload;
      setDataList(loanListApiResponse);
    }
    setIsLoading(false);
  };

  const ReportListConfig = ({ dataSource }) => {
    const columnsTemplate: IColumns[] = [
      { dataField: 'optName', caption: 'Dashboard' },
      {
        type: 'buttons',
        caption: 'View',
        name: 'view',
        visible: true,
        minWidth: 50,
        showInColumnChooser: true,
      },
    ];

    const data = useMemo(() => dataSource, [dataSource]);
    const columns = useMemo(() => columnsTemplate, [columnsTemplate]);

    return [data, columns];
  };


  const [data, column] = ReportListConfig({ dataSource: dataList });
  return (
    <div>
      <PageHeader title="CR Group Report" backButton="/" />
      <Grid item xs={12}>
        <Table
          dataSource={data}
          columns={column}
          keyExpr="optCode"
          roleLevel={userContext.roleLevel}
          isLoading={isLoading}
          storageKey="datagridReport"
          onView={(optCode) => { navigate(`/report-detail/${optCode}`); }}
        />
      </Grid>
    </div>
  );
}
