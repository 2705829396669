import React, { createContext, useState, useContext } from 'react';
import AppGlobalDialog from './AppGlobalDialog';

interface DialogState {
  isOpen: boolean;
  type: 'confirmation' | 'loading' | 'information'; // Add type for dialog type
  title?: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  actionType?: 'default' | 'deletion'; // Define actionType in DialogState
}

interface AppGlobalDialogContextType {
  dialogState: DialogState;
  openDialog: (options: Partial<DialogState> & { actionType?: 'default' | 'deletion' }) => void; // Add actionType to openDialog function
  closeDialog: () => void;
  showLoadingDialog: (options?: ShowLoadingDialogOptions) => void;
  hideLoadingDialog: () => void;
  showInformationDialog: (options: ShowInformationDialogOptions) => void;
  showConfirmationDialog: (options: ShowConfirmationDialogOptions) => void;
}

interface ShowLoadingDialogOptions {
  message?: string;
  title?: string;
}

interface ShowInformationDialogOptions {
  message: string;
  title?: string;
  onConfirm?: () => void;
}

interface ShowConfirmationDialogOptions {
  message: string;
  title?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  actionType?: 'default' | 'deletion';
}

const AppGlobalDialogContext = createContext<AppGlobalDialogContextType | undefined>(undefined);

export const useAppGlobalDialog = () => {
  const context = useContext(AppGlobalDialogContext);
  if (!context) {
    throw new Error('useAppGlobalDialog must be used within a AppGlobalDialogProvider');
  }
  return context;
};

export const AppGlobalDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    type: 'confirmation', // Default type to 'confirmation'
    message: '',
    onConfirm: () => { },
    onCancel: () => { },
    actionType: 'default', // Default actionType to 'default'
  });

  const openDialog = ({
    type = 'confirmation', // Set type to 'confirmation' by default
    message,
    title = '',
    onConfirm,
    onCancel,
    actionType,
  }: Partial<DialogState> & { actionType?: 'default' | 'deletion' }) => {
    setDialogState({
      isOpen: true,
      type,
      title,
      message: message || '',
      onConfirm: onConfirm || (() => { }),
      onCancel: onCancel || (() => { }),
      actionType: actionType || 'default', // Set actionType or default to 'default'
    });
  };

  const closeDialog = () => {
    setDialogState(prevState => ({ ...prevState, isOpen: false }));
  };

  const showLoadingDialog = (options?: ShowLoadingDialogOptions) => {
    openDialog({
      type: 'loading',
      message: options?.message ?? 'Sedang diproses . . .',
      title: options?.title
    });
  };


  const showInformationDialog = ({ message, title, onConfirm }: ShowInformationDialogOptions) => {
    openDialog({
      type: 'information',
      message,
      title,
      onConfirm: () => {
        closeDialog();
        if (onConfirm) {
          onConfirm();
        }
      }
    });
  };

  const showConfirmationDialog = ({ message, title, onConfirm, onCancel, actionType }: ShowConfirmationDialogOptions) => {
    openDialog({
      type: 'confirmation',
      message,
      title,
      onConfirm: () => {
        closeDialog();
        if (onConfirm) {
          onConfirm();
        }
      },
      onCancel: () => {
        closeDialog();
        if (onCancel) {
          onCancel();
        }
      },
      actionType,
    });
  };

  const hideLoadingDialog = () => {
    closeDialog();
  };

  return (
    <AppGlobalDialogContext.Provider value={{ dialogState, openDialog, closeDialog, showLoadingDialog, showInformationDialog, showConfirmationDialog, hideLoadingDialog }}>
      {children}
      <AppGlobalDialog />
    </AppGlobalDialogContext.Provider>
  );
};