import isLocalStorageAvailable from './isLocalStorageAvailable';

const setPersistentData = (key, value) => {
  if (isLocalStorageAvailable()) {
    return window.localStorage.setItem(key, value);
  }
  return null;
};

export default setPersistentData;
