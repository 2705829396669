import React from 'react';

interface ISubsectionProps {
  label: React.ReactNode;
  children: React.ReactNode;
  classNames?: string;
}

function Subsection({ label, children, classNames }: ISubsectionProps) {
  return (
    <div className={`mb-6 ${classNames}`}>
      <span className="block font-bold text-sm text-blue-600 underline mb-4">{label}</span>
      <div className="px-2">{children}</div>
    </div>
  );
}

Subsection.defaultProps = {
  classNames: '',
};

export default Subsection;
