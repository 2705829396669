import React, {
  useEffect, useState, lazy, Suspense,
} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { SnackbarContext } from 'context/snackbar';
import NavBar from 'components/navbar/NavBar';
import { useIsAuthenticated } from '@azure/msal-react';
import { Spinner } from 'components/basic';
import { AppContext } from 'context/app';
import { UserContext } from './context/user';
import Footer from './components/footer/Footer';
import { AxiosError } from 'axios';
import ReportListScreen from 'screen/Reports/ReportList';
import ReportDetailScreen from 'screen/Reports/ReportDetail';

// use lazy import to improve initial page load
const Login = lazy(() => import('screen/Login/Login'));
const Profile = lazy(() => import('screen/Profile/Profile'));
const LoginOAuth365 = lazy(() => import('screen/LoginOAuth365/LoginOAuth365'));
const Home = lazy(() => import('screen/Home'));

const FormCollection = lazy(() => import('screen/Loan/collection/section/FormCollection'));
const LoanList = lazy(() => import('screen/Loan/LoanList'));
const LoanEdit = lazy(() => import('screen/Loan/LoanDetail'));
const ListAssigment = lazy(() => import('screen/CreditRecovery/ListAssigment'));
const ListCR = lazy(() => import('./screen/CreditRecovery/ListCR'));
const ListSuratPeringatan = lazy(() => import('screen/Document/ListSuratPeringatan'));

export default function AllRoutes() {
  const [snackbar, setSnackbar] = useState({
    show: false,
    message: '',
    severity: 'success',
  });
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const [roleLevel, setRoleLevel] = useState(100);
  const [scrollToId, setScrollToId] = useState<string | null>(null);
  const microsoftIsAuthenticated = useIsAuthenticated();
  const [oAuth365Done, setOAuth365Done] = useState(false);
  const [featureFlag, setFeatureFlag] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const tokenExpiredAt = localStorage.getItem('tokenExpiredAt');
    if (token && tokenExpiredAt) {
      const currentDate = new Date();
      const tokenExpiredDate = new Date(tokenExpiredAt);
      if (currentDate <= tokenExpiredDate) return setAuthenticated(true);
    }
    return setAuthenticated(false);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      if (!window.location.search.includes('redirect')) {
        const currentUrl = window.location.pathname + window.location.search;
        const newSearch = `?redirect=${encodeURIComponent(currentUrl)}`;
        window.history.replaceState({}, '', newSearch);
      }
      return;
    }
    const storedEmail = localStorage.getItem('emailUser');
    if (storedEmail) {
      setEmail(storedEmail);
    }
    const storedRoleLevel = JSON.parse(localStorage.getItem('roleLevel'));
    if (storedRoleLevel) {
      setRoleLevel(storedRoleLevel);
    }
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        scrollTo: { id: scrollToId, setId: setScrollToId },
        config: featureFlag as Record<string, boolean>,
      }}
    >
      <UserContext.Provider
        value={{
          isAuthenticated,
          setAuthenticated: (v) => setAuthenticated(v),
          roleLevel,
          email,
        }}
      >
        <SnackbarContext.Provider
          value={{
            show: (message, severity = 'success') => {
              setSnackbar({ show: true, message, severity });
            },
            showError: (error: any) => {
              let errorMessage = error ?? 'Terjadi kesalahan saat melakukan aksi ini, silahkan coba lagi.';
              if (error instanceof AxiosError) {
                errorMessage = error.response?.data?.message;
              }
              setSnackbar({ show: true, message: errorMessage, severity: 'error' });
            },
          }}
        >
          <Suspense
            fallback={(
              <div className="h-screen w-full flex justify-center items-center">
                <Spinner color="blue" size="sm" flexGrow={0} />
              </div>
            )}
          >
            <Router>
              <div className="flex flex-col justify-between min-h-screen">
                <NavBar />
                <Snackbar
                  open={snackbar.show}
                  autoHideDuration={6000}
                  onClose={() => setSnackbar({ ...snackbar, show: false })}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Alert
                    onClose={() => setSnackbar({ ...snackbar, show: false })}
                    severity={snackbar.severity as AlertColor}
                    sx={{ width: '100%' }}
                  >
                    {snackbar.message}
                  </Alert>
                </Snackbar>
                <div className="root-app mx-auto">
                  {isAuthenticated && (
                    <Routes>
                      <Route caseSensitive path="/" element={<Home />} />
                      <Route
                        caseSensitive
                        path="/profile"
                        element={<Profile />}
                      />
                      <Route
                        caseSensitive
                        path="/admin-cr"
                        element={<ListCR />}
                      />
                      <Route
                        caseSensitive
                        path="/report-list"
                        element={<ReportListScreen />}
                      />
                      <Route
                        caseSensitive
                        path="/report-detail/:id"
                        element={<ReportDetailScreen />}
                      />
                      <Route
                        caseSensitive
                        path="/assigment-cr"
                        element={<ListAssigment />}
                      />
                      <Route
                        caseSensitive
                        path="/project"
                        element={<LoanList type="CR" />}
                      />
                      <Route
                        caseSensitive
                        path="/project/:id"
                        element={<LoanEdit type="CR" />}
                      />
                      <Route
                        caseSensitive
                        path="/project/collection/:id"
                        element={<FormCollection />}
                      />
                      <Route path="*" element={<Home />} />
                    </Routes>
                  )}
                  {!isAuthenticated && (
                    <Routes>
                      <Route
                        path="/login"
                        element={<Login setOAuth365Done={setOAuth365Done} />}
                      />
                      <Route
                        path="*"
                        element={<Login setOAuth365Done={setOAuth365Done} />}
                      />
                    </Routes>
                  )}
                  {microsoftIsAuthenticated && oAuth365Done && (
                    <Routes>
                      <Route path="*" element={<LoginOAuth365 />} />
                    </Routes>
                  )}
                </div>
                <div className="flex-1" />
                <Footer />
              </div>
            </Router>
          </Suspense>
        </SnackbarContext.Provider>
      </UserContext.Provider>
    </AppContext.Provider>
  );
}
