import dayjs from 'dayjs';

const FORMAT_DATE_TIME_INPUT = 'YYYY-MM-DD HH:mm:ss';
const FORMAT_DATE_TIME_INPUT_FILE = 'YYYY-MM-DD_HH-mm-ss';
const FORMAT_DATE_INPUT = 'YYYY-MM-DD';

const formatList = {
  file_datetime: FORMAT_DATE_TIME_INPUT_FILE,
  datetime: FORMAT_DATE_TIME_INPUT,
  date: FORMAT_DATE_INPUT,
};

const parseDate = (fmt: 'date' | 'datetime' | 'file_datetime', date = undefined) => {
  const format = formatList[fmt];
  return dayjs(date).format(format);
};

export { FORMAT_DATE_TIME_INPUT, parseDate };
