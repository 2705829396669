import isLocalStorageAvailable from './isLocalStorageAvailable';

const getPersistentData = (key) => {
  if (isLocalStorageAvailable() && window.localStorage.getItem(key)) {
    return window.localStorage.getItem(key);
  }

  return null;
};

export default getPersistentData;
