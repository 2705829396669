import { CircularProgress } from '@mui/material';
import { getReportDetail } from 'client/request/reports';
import PageHeader from 'components/PageHeader';
import { SnackbarContext } from 'context/snackbar';
import { useUserContext } from 'context/user';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { asyncWrapper } from 'utility/asyncWrapper';
import { useAppGlobalDialog } from 'utility/popup/dialog/AppGlobalDialogContext';

export default function ReportDetailScreen() {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const snackbar = useContext(SnackbarContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState(null);

  useEffect(() => {
    apiGetReportDetail();
  }, [userContext]);

  const apiGetReportDetail = async () => {
    setIsLoading(true);
    const [res, err] = await asyncWrapper(getReportDetail(id));
    if (err) snackbar.show(err, 'error');
    if (res) {
      const loanListApiResponse = res.data;
      setIframeUrl(loanListApiResponse.payload);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <PageHeader title="CR Group Report" backButton="/" />
      {isLoading &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      }
      {iframeUrl &&
        <div className="iframe-container" style={{ width: '100vw', height: '100vh' }}>
          <iframe
            src={iframeUrl}
            width="95%"
            height="70%"
            allowTransparency
            style={{ border: 'none' }}
          />
        </div>
      }
    </div>
  );
}
