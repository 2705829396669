// AppGlobalDialog.tsx
import React from 'react';
import { useAppGlobalDialog } from './AppGlobalDialogContext';
import { CircularProgress } from '@mui/material';

const AppGlobalDialog: React.FC = () => {
  const { dialogState } = useAppGlobalDialog();
  const dialogStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '20px',
    zIndex: 1000,
  };

  const contentStyle = {
    maxWidth: '600px',
    overflow: 'auto',
    padding: '20px',
    borderRadius: '8px',
  };

  const renderDialog = () => {
    switch (dialogState.type) {
      case 'confirmation':
        return (
          <div style={dialogStyle}>
            <div style={contentStyle}>
              <div className="bg-white p-4 rounded shadow-lg">
                {dialogState.title && <h2 className="text-lg font-semibold mb-2">{dialogState.title}</h2>}
                <div>
                  {dialogState.message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  <button onClick={dialogState.onCancel} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                    Batal
                  </button>
                  <button onClick={dialogState.onConfirm} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 'loading':
        return (
          <div style={dialogStyle}>
            <div className="bg-white p-4 rounded shadow-lg">
              <div className="flex items-center justify-center mb-4">
                <CircularProgress color="primary" size={60} />
              </div>
              <p>{dialogState.message ?? 'Sedang di proses . . .'}</p>
            </div>
          </div>
        );
      case 'information':
        return (
          <div style={dialogStyle}>
            <div style={contentStyle}>
              <div className="bg-white p-4 rounded shadow-lg">
                {dialogState.title && <h2 className="text-lg font-semibold mb-2">{dialogState.title}</h2>}
                <div>
                  {dialogState.message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <div className="mt-4 flex justify-end">
                  <button onClick={dialogState.onConfirm} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return dialogState.isOpen ? renderDialog() : null;
};

export default AppGlobalDialog;